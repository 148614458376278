@import "../../scss/variable";
@import "../../scss/mixins";
.signup-complete {
  background-color: $black;
  &-container {
    display: flex;
    &-left {
      width: 30%;
      @include respond(phone) {
        display: none;
      }
    }
    &-right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond(phone) {
        width: 100%;
        height: 100vh;
      }
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        @include respond(phone) {
          width: 80%;
        }
        // &-logo {
        //   margin-bottom: 1rem;
        // }
        &-heading {
          margin: 3rem 0rem;
          & > h1 {
            font-size: 3rem;
            color: $white;
            font-weight: 700;
          }
        }
        &-subheading {
          margin-bottom: 3rem;
          & > h3 {
            color: $gray;
            font-weight: 300;
          }
        }

        &-loading {
          margin-top: 8rem;
          width: 80%;
        }
      }
    }
  }
}
