$before: linear-gradient(269.18deg, #6d4273 1.24%, #faae7b 110.92%);
$after: linear-gradient(266.19deg, #432371 -12.23%, #faae7b 101.54%);

//  Back Ground

$black: #1e1e1e;
$light-black: #3f3f3f;

$background: linear-gradient(194.89deg, #300076 -10.69%, #faae7b 104.43%);

//  Logo Back Ground
$logo-background: linear-gradient(185.89deg, #b85d70 0.55%, #ec9d4f 100%);

// DashBoard BackGround
$dashboard: linear-gradient(
  90.23deg,
  #df8d57 6.94%,
  rgba(223, 141, 87, 0) 84.3%
);

// Typo Graphy

$white: #e1e1e1;
$gray: gray;

$red: #ff0000;
$green: #00ff47;
