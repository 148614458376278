@import "../../scss/variable";
.banner {
  background-color: orange;
  width: 95%;
  height: 28vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  background-image: $logo-background;
  &-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    & > h4 {
      color: $white;
      text-transform: uppercase;
      font-size: 1.8rem;
      margin-top: 1rem;
      font-weight: 500;
    }
  }
}
