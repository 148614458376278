@import "../../scss/variable";

.input {
  margin-top: 2rem;
  &-container {
    border: 2px solid $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.3rem;
    // Logo  For Input Field
    &-logo {
      margin-left: 1rem;

      & > img {
        height: 2rem;
        width: 2rem;
      }
    }
    &-input {
      width: 89%;
      padding: 1.2rem 3rem;
      border: none;
      border-radius: 1rem;
      font-size: 1.5rem;
      &:focus {
        outline: none;
      }
    }
  }
}

.form {
  &-input {
    width: 100%;

    opacity: 0.4;
  }
}

#input-error {
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
}
