.page_404 {
  // padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
  height: 100vh;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  width: 70%;
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  background-position: center;
}

// .four_zero_four_bg h1 {
//   font-size: 6rem;
// }

.heading-for-access-im {
  margin-top: -2rem;
  & > h1 {
    font-size: 8rem;
  }
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #002289;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
}
