@import "../../scss/mixins";
.page_404 {
  padding: 40px 0;
  background: #fff;
  height: 100vh;
  font-family: "Roboto";
  @include respond(phone) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px;
    overflow: hidden;
  }
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  width: 70%;
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  margin: auto;
  @include respond(phone) {
    width: 100%;
  }
}

.four_zero_four_bg h1 {
  font-size: 80px;
  @include respond(phone) {
    font-size: 30px;
  }
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #002289;
  margin: 20px 0;
  border-radius: 8px;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
