@import "../../scss/variable";
@import "../../scss/mixins";
.left {
  width: 100%;

  &-container {
    height: 95vh;
    @include respond(phone) {
      height: 50vh;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-top {
      &-menu {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        &-item {
          width: 100%;
          padding: 1.5rem 0rem;
          background-image: $dashboard;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          & > img {
            margin-right: 1.4rem;
          }
          & > h4 {
            color: $white;
            font-size: 1.6rem;
            font-weight: 300;
          }
        }
      }
    }
    &-bottom {
      @include respond(phone) {
        display: none;
      }
      &-content {
        width: 70%;
        margin: auto;
        &-item {
          margin-top: 1rem;
          display: flex;
          align-items: center;

          & > img {
            margin-right: 1rem;
            height: 1.5rem;
            width: 1.5rem;
          }
          & > h5 {
            color: $gray;
            font-size: 1.2rem;
            font-weight: 300;
          }
        }
        &-section {
          margin-top: 1rem;
          width: 95%;
          border: 1px solid $white;
          padding-right: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 1rem;
          & > h3 {
            color: white;
            font-size: 1.6rem;
            font-size: 200;
            & > p {
              font-size: 1rem;
              font-weight: 300;
              color: gray;
            }
          }
          & > div {
            height: 4rem;
            width: 4rem;
            margin: 0.2rem;
            background-image: $logo-background;
            border-radius: 1rem;
          }
        }
      }
    }
  }
}
