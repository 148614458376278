@import "../../scss/variable";
@import "../../scss/mixins";

.dashboardtwo {
  background-color: $black;
  padding-bottom: 4rem;
  padding-top: 4rem;
  &-container {
    display: flex;
    @include respond(phone) {
      flex-direction: column;
    }
    &-left {
      width: 17%;
      @include respond(phone) {
        width: 100%;
      }
    }
    //  Middle
    &-middle {
      width: 61%;
      @include respond(phone) {
        width: 96%;
      }
      &-content {
        &-heading {
          width: 90%;
          margin: auto;
          & > p {
            color: $gray;
            font-size: 1.5rem;
          }
          & > h2 {
            margin-top: -1.5rem;
            color: $white;
            font-size: 3rem;
          }
        }
        &-container {
          background-color: #151515;
          border: 1px solid $gray;
          border-radius: 1.5rem;
          margin-left: 2rem;
          padding: 2rem;
          &-flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > h1 {
              color: white;
              font-size: 2.2rem;
            }
            &-btn {
              display: flex;
              align-items: center;
              @include respond(phone) {
                flex-direction: column;
              }
              &-first {
                margin-right: 1rem;
                & > button {
                  padding: 0.5rem 0.5rem;
                  border-radius: 0.8rem;
                  background: none;
                  font-size: 1.1rem;
                  font-weight: 600;
                  color: $white;
                  cursor: pointer;
                }
              }
              &-second {
                margin-right: 1rem;
                & > button {
                  & > img {
                    margin-right: 0.7rem;
                    height: 1.1rem;
                  }
                  background-image: linear-gradient(
                    266.37deg,
                    #b75b71 1.94%,
                    #ee9f4e 97.07%
                  );

                  color: $white;
                  cursor: pointer;
                  padding: 0.7rem 0.8rem;
                  border-radius: 0.8rem;
                  font-size: 1.2rem;
                  font-weight: 600;
                  border: none;
                }
              }
            }
          }
          &-tabel {
            margin-top: 2rem;
          }
        }
      }
    }
    &-right {
      width: 22%;
      padding: 0rem 1rem;
      @include respond(phone) {
        width: 100%;
        padding: 0px;
        margin-top: 2rem;
      }
      &-date {
        & > p {
          color: $gray;
          font-size: 1.5rem;
        }
      }
      &-news {
        background-image: linear-gradient(
          156.92deg,
          rgba(255, 255, 255, 0) -28.21%,
          #ffffff2b 110.74%
        );
        width: 95%;
        margin: auto;
        border-radius: 1.5rem;
        padding: 1rem;
        border: 1px solid #949494;
        &-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &-heading {
            padding: 0.3rem 0rem;
            width: 100%;
            text-align: center;
            font-size: 1.3rem;
            font-weight: 500;
            color: $white;
          }
          &-today {
            display: flex;
            align-items: center;
            justify-content: space-between;
            & > span {
              font-size: 1.1rem;
              padding: 0.2rem;
              color: #949494;
              text-transform: uppercase;
              letter-spacing: 0.3rem;
            }
          }
          &-item {
            width: 95%;
            border: 1px solid $white;
            margin-top: 1rem;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            border-radius: 0.7rem;
            &-to {
              & > p {
                color: $gray;
                font-size: 1.3rem;
              }
            }
            &-name {
              display: flex;
              flex-direction: column;
              align-items: center;

              & > p {
                font-size: 1.2rem;
                color: $white;
              }
              &-noti {
                width: 100%;
                background-color: #d9d9d928;
                padding: 0.1rem 0.8rem;
                border-radius: 0.2rem;
                & > i {
                  color: $gray;
                  font-size: 1.2rem;
                }
              }
            }
            &-arrow {
              & > img {
                height: 0.7rem;
                width: 1rem;
              }
            }
          }
        }
      }
    }
  }
}

.scrollit {
  overflow-y:scroll!important;
  height:490px;
}
.search-input{
  width: 300px;
  margin-right: 40px;
  font-size:1.2rem !important;
}
.email-button-edit {
  margin-top: 3rem;
  & > button {
    border: none;
    padding: 1rem 4rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: $white;
    cursor: pointer;
    background-image: linear-gradient(
      114.68deg,
      #efa04e 28.66%,
      #b65a72 77.56%
    );
  }
}

.bordered {
  border-left: 1px solid $light-black;
  border-right: 1px solid $light-black;
}

.tabel-heading-first {
  text-align: left;
  padding-left: 2rem;
}
.td-btn {
  color: $gray;
  background: none;
  border: 1px solid gray;
  // padding: 0.3rem 1.8rem;
  width: 9rem;
  font-size: 1.1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.td-white {
  color: $white;
  font-size: 1.2rem;
  font-weight: 300;
}
// DashBoard Style

.content {
  width: 100%;
  margin: auto;
  border: 1px solid $light-black;
  border-radius: 16px;
  overflow: hidden;
  @include respond(phone) {
    overflow-x: scroll;
  }
}
table {
  width: 100%;
}
thead {
  background-color: $light-black;
  color: $white;
  font-weight: 300;
  font-size: 1.3rem;
}

td {
  text-align: center;
  padding: 0.8rem 0rem;
  color: $gray;
  font-size: 1.2rem;
}
th {
  padding: 10px;
}
.bordered {
  border-left: 1px solid $light-black;
  border-right: 1px solid $light-black;
}

.td-border {
  @include respond(phone) {
    border-bottom: 1px solid gray;
  }
}
// Color Code

// pagination
.pagination {
  margin-left: 3rem;
  margin-right: 3rem;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  background-color: $light-black;
  padding: 1rem 0rem;
  color: whtie;
  @include respond(phone) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    padding: 0rem;
    border-radius: 1rem;
  }
}
.send-btn {
  background-image: linear-gradient(114.68deg, #efa04e 28.66%, #b65a72 77.56%);
  color: white;
  font-weight: 500;
  font-size: 1.6rem;
  border: none;
  padding: 0.5rem 4rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.pop-modal {
  // background-color: #151515;
  border-radius: 1px solid white;

  & > input {
    width: 100%;
    padding: 1rem;
    border-radius: 1rem;
    border: none;
    // background-color: gray;

    &:focus {
      outline: none;
    }
  }
}

// BTNS
.btn-td {
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(phone) {
    flex-direction: column;
    & > * {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }
  }
}

.loader{
  text-align: center;
  padding-top: 200px;
  width:99.9%;

}
.center {
  text-align: center;
}
.t-l {
  text-align: left;
  padding-left: 1rem;
}

.p-t {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.green {
  color: green;
}
.red {
  color: red;
}
.not-found {
  color: white;
  margin-top: 2rem;
  text-transform: uppercase;
}
.dashboard-btn-flex {
  display: flex;
  align-items: center;
  @include respond(phone) {
    flex-direction: column;
    margin-top: 1rem;
  }
  & > * {
    @include respond(phone) {
      margin-bottom: 1rem;
    }
  }
}
