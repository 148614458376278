@import "../../scss/variable";
@import "../../scss/mixins";
.signup-three {
  background-color: $black;
  &-container {
    display: flex;
    &-left {
      width: 30%;
      @include respond(phone) {
        display: none;
      }
    }
    &-right {
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond(phone) {
        height: 100vh;
        width: 100%;
      }
      &-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 40%;
        @include respond(phone) {
          width: 80%;
        }
        &-heading {
          margin: 3rem 0rem;
          & > h1 {
            font-size: 3rem;
            color: $white;
            font-weight: 700;
          }
        }
        &-form {
          width: 100%;
          &-btn {
            margin-top: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            & > * {
              &:not(:last-child) {
                margin-right: 1rem;
              }
            }
          }
        }
        &-loading {
          margin-top: 3rem;
          width: 80%;
        }
      }
    }
  }
}
