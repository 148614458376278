@import "../../scss/variable";
.btn-first {
  padding: 1rem 5rem;
  color: #ffffff;
  background-color: $white;
  border: none;
  border-radius: 1rem;
  background-image: $before;
  cursor: pointer;
  transition: 1s ease-in-out;
  font-size: 1.6rem;
  font-weight: 600;
  &:hover {
    background-image: $after;
  }
}

.btn-second {
  padding: 0.8rem 5rem;
  color: #ffffff;
  background-color: $white;
  border-radius: 1rem;
  // background-image: $before;
  border: 0.3rem solid white;
  background: transparent;
  cursor: pointer;
  transition: 1s ease-in-out;
  font-size: 1.6rem;
  font-weight: 600;
  &:hover {
    background-image: $after;
    border: none;
     padding: 1rem 5rem;
  }
}
