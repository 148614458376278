@import "../../scss/variable";

.newinput {
  margin-top: 2rem;
  & > label {
    color: $white;

    font-size: 1.5rem;
    font-weight: 600;
  }
  &-container {
    margin-top: 1rem;
    &-input {
      width: 100%;
      color: white;
      padding: 1rem 0.5rem;
      background: none;
      border: 1px solid white;
      border-radius: 1rem;
      font-size: 1.5rem;
      &:focus {
        outline: none;
      }
    }
  }
}

#newinput-error {
  color: white;
  font-size: 1.3rem;
  font-weight: 300;
}

//  Text Area

.newtext {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  & > label {
    color: $white;

    font-size: 1.5rem;
    font-weight: 600;
  }
  & > textarea {
    margin-top: 1rem;
    background: none;
    padding: 1rem 0.5rem;
    border-radius: 1rem;
    border: 1px solid white;
    font-size: 1.5rem;
  }
}
