@import "../../scss/variable";
@import "../../scss/mixins";
.forget {
  background-color: $black;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &-content {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include respond(phone) {
      width: 100%;
    }
    &-logo {
      margin-bottom: 2rem;
    }
    &-heading {
      color: white;
      font-size: 1.3rem;
    }
    &-form {
      width: 50%;
      &-btn {
        margin-top: 2rem;
      }
    }
  }
}
