@import "../../scss/variable";
.loading {
  margin-top: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &-box {
    padding: 0.1rem;
    width: 100%;

    background-color: $white;
    border-radius: 2rem;

    &-line {
      height: 0.5rem;
      border-radius: 2rem;
      background-image: $before;
      animation: loading infinite 5s forwards cubic-bezier(0, 0, 0, 0);
    }
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
