@import "../../scss/variable";
@import "../../scss/mixins";

.sidebar {
  &-container {
    height: 100vh;
    background-position: center;
    object-fit: cover;
    background-image: linear-gradient(194.89deg, #2f0076d3 -10.69%, #faae7bcc 104.43%),
    url(./../../assest/Sidebar/images.png);
    &-btn {
      margin-top: 70%;
      @include respond(phone) {
        margin-top: 180%;
      }
      float: right;
      &-first {
        margin-bottom: 1rem;

        & > button {
          float: right;
          color: #cd8b78;
          font-weight: 600;
          background-color: $black;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border: none;
          padding: 1rem 4rem;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
      &-second {
        float: right;
        margin-top: 1rem;
        & > button {
          font-weight: 600;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border: none;
          padding: 1rem 4.2rem;
          text-transform: uppercase;
          color: $white;
          background: none;
          border: 2px solid $white;
          border-right: none;
          cursor: pointer;
        }
      }
    }
  }
}
