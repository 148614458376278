@import "../../scss/variable";
.dropdown {
  width: 150px;
  margin-top: 1rem;
  position: relative;

  &-btn {
    padding: 1.2rem 1rem;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0.8rem;
    border: 1px solid white;
    & > p {
      color: $white;
      font-size: 1.3rem;
      font-weight: 400;
    }
  }
  &-content {
    position: absolute;
    top: 110%;
    padding: 15px;
    background: #fff;
    border: 1px solid $white;
    border-radius: 1rem;
    font-weight: bold;
    width: 85%;
    left: 0;
  }
  &-item {
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background-color: #fcfcfc;
    }
  }
}
