@import "../../scss/variable";
@import "../../scss/mixins";

.member {
  background-color: $black;

  padding-top: 4rem;
  padding-bottom: 4rem;
  &-container {
    display: flex;
    @include respond(phone) {
      flex-direction: column;
    }
    &-left {
      width: 17%;
      @include respond(phone) {
        width: 100%;
      }
    }
    //  Middle
    &-middle {
      width: 61%;
      @include respond(phone) {
        width: 100%;
      }
      &-content {
        // BTNS
        &-heading {
          width: 90%;
          margin: auto;
          & > p {
            color: $gray;
            font-size: 1.5rem;
          }
          & > h2 {
            color: $white;
            font-size: 2.6rem;
          }
        }
        &-main {
          margin-top: 2rem;
          background-color: #151515;
          padding: 2rem;
          border-radius: 1.8rem;
          display: flex;

          &-left {
            width: 100%;

            &-heading {
              & > h1 {
                color: $white;
              }
            }
          }
          // &-right {
          //   width: 50%;
          //   position: relative;
          // }
        }
      }
    }
    &-right {
      width: 22%;
      padding: 0rem 1rem;
      @include respond(phone) {
        width: 100%;
        padding: 0px;
        margin-bottom: 4rem;
      }
      &-date {
        & > p {
          color: $gray;
          font-size: 1.5rem;
          float: right;
        }
      }

      // for news section
      &-news {
        background-image: linear-gradient(
          156.92deg,
          rgba(255, 255, 255, 0) -28.21%,
          #ffffff2b 110.74%
        );
        width: 85%;
        margin: auto;
        border-radius: 1.5rem;
        padding: 1rem;
        border: 1px solid #949494;
        &-heading {
          & > h2 {
            color: $white;
            font-size: 1.5rem;
            font-weight: 500;
          }
        }
        &-today {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > span {
            font-size: 1.1rem;
            padding: 1rem;
            color: #949494;
            text-transform: uppercase;
            letter-spacing: 0.3rem;
          }
        }
        &-large {
          height: 25vh;
          background-color: #94949428;
          border-radius: 1rem;
          margin: 1rem 0rem;
        }
        &-small {
          height: 15vh;
          background-color: #94949428;
          border-radius: 1rem;
          margin: 1rem 0rem;
        }
      }
    }
  }
}
.dropdown-heading {
  margin-top: 2rem;
  color: $white;
  font-size: 1.5rem;
  font-weight: 600;
}

.send-invite {
  border: none;
  padding: 0.8rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: white;
  background-image: linear-gradient(266.37deg, #b75b71 1.94%, #ee9f4e 97.07%);
  cursor: pointer;
  display: flex;
  & > img {
    margin-right: 1.2rem;
  }
}

.divider {
  border-bottom: 1px solid #949494;
  width: 100%;
  text-transform: uppercase;
}
